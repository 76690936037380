<template>
  <div id="development">
    <Component
      v-for="(component, index) in children"
      v-bind:key="index"
      v-bind:is="component.name"
      v-bind:props="component.props"
    />
  </div>
</template>

<script>
export default {
  created() {
    if (!this.$route.query.postalCode || !this.$route.query.selectedPackage) {
      this.$router.replace("/");
    }
  },
  computed: {
    children() {
      return this.$store.getters["system/pages"].find(
        (page) => page.name == "Insured"
      ).children;
    },
  },
};
</script>